import { isObject } from "lodash";
import Environment from "./Environment";
import { FnaaRaw } from "./Fnaa";
import {
  QuotationFormValues,
  QuotationLastStep,
  QuotationResponses,
  QuotationResult,
  QuotationStep,
} from "./Quotations";

async function jsonParser<TOutput>(response: Response) {
  const json = await response.json();
  return json as TOutput;
}

async function xmlParser<TOutput>(response: Response) {
  const text = await response.text();
  const xml = new window.DOMParser().parseFromString(text, "text/xml");
  return xml;
}

type RequestParams<TResult> = {
  domain: string;
  search?: { [key: string]: string };
  body?: any;
  parser?: (response: Response) => Promise<TResult>;
  method?: string;
};

async function request<TResult = any>(params: RequestParams<TResult>) {
  const domain = params.domain;
  const parser = params.parser || jsonParser;
  const method = params.method || "GET";
  const body = params.body;
  const search = params.search;

  let url = `${domain}/wp-admin/admin-ajax.php`;
  const searchParams = new URLSearchParams();
  if (search) {
    Object.entries(search).forEach(([key, value]) => {
      if (!value) return;
      searchParams.set(key, value.toString());
    });
    url = `${url}?${searchParams.toString()}`;
  }

  const req: RequestInit = { method };
  if (body !== undefined) {
    if (body instanceof FormData) req.body = body;
    else if (isObject(body)) {
      req.body = JSON.stringify(body);
    }
  }
  const response = await fetch(url, req);
  const result = await parser(response);
  return result;
}

function initializeQuotation() {
  return request<QuotationStep>({
    domain: Environment.findString("REACT_APP_API"),
    search: {
      action: "init_webservice",
      origine: Environment.findString("REACT_APP_STEPS_ORIGIN"),
    },
  });
}

function continueQuotation(current: string, responses: QuotationResponses) {
  const search = { ...responses };
  for (let searchKey in search) {
    const value = search[searchKey];
    if (!(typeof value === "string" || typeof value === "number")) {
      delete search[searchKey];
    }
  }
  return request<QuotationStep | QuotationLastStep>({
    domain: Environment.findString("REACT_APP_API"),
    search: {
      action: "init_webservice",
      origine: Environment.findString("REACT_APP_STEPS_ORIGIN"),
      current,
      ...search,
    },
    method: "POST",
    body: responses,
  });
}

async function getFnaaInfos(
  immat: string,
  reponses: QuotationResponses
): Promise<FnaaRaw> {
  const xmlDoc = await request<Document>({
    domain: Environment.findString("REACT_APP_API"),
    search: {
      action: "fnaa",
      origine: Environment.findString("REACT_APP_STEPS_ORIGIN"),
      immat,
      ...reponses,
    },
    parser: xmlParser,
  });

  // les données recuperees du webservice
  let datas: FnaaRaw = {};
  // recupere les infos necessaires dedans
  // verifie si pas d'erreur

  const error = xmlDoc.getElementsByTagName("erreur").item(0);
  if (error) {
    const errorCounterNode = xmlDoc.getElementsByTagName("numero").item(0);
    const error_number = errorCounterNode ? errorCounterNode.innerHTML : "+";
    throw {
      code: "ERROR",
      msg: error.innerHTML + " (" + error_number + ")",
    };
  }
  let fault = xmlDoc.getElementsByTagName("Fault");
  if (fault.length > 0) {
    throw { code: "UNKNOWN", msg: "immatriculation inconnue" };
  }

  let rep = xmlDoc.getElementsByTagNameNS(
    "http://aaa.asso.fr/sivin/schemas",
    "return"
  );

  if (rep && rep.length > 0) {
    let reponse = rep[0].children;
    let count = reponse.length;
    for (let elem of Array.from(reponse)) {
      let key = REMAP[elem.localName] || elem.localName; // si inconnu, le nom correspond
      if (elem.localName.startsWith("date")) {
        // parse les differentes dates
        datas[key] = parseDate(elem);
      } else datas[key] = elem.innerHTML; // recup simplement
    }
  } else {
    // reponse totalement inconnue
    throw { code: "ERROR", msg: "Reponse inconnue du serveur fnaa." };
  }

  // mapping des differents valeurs : principalement essence
  let v = datas["energie"];
  // ('mapping des differentes energies possibles');
  // (v);
  if (v && v in MAPPING) datas["energie"] = MAPPING[v];
  else datas["energie"] = HYBRID;

  // pour connaitre le type recuperer en fonction de ce que nous utilisons
  v = datas["genreV"];

  if (v == "QM" && datas["carrosserie"] == "QLOMP") {
    datas["type_vehicule"] = VOITURE;
  } else if (v && v in TYPE_MAPPING) {
    datas["type_vehicule"] = TYPE_MAPPING[v];
  }

  if (datas["type_vehicule"] === undefined) {
    throw {
      code: "UNSUPPORTED",
      msg: "",
    };
  }

  return datas;
}

type FinalizeQuotationResponse = QuotationResult | { calculated_datas: false };

async function getGeolocDestinations(
  responses: QuotationResponses,
  dep: string,
  city: string,
  lat: number,
  lng: number,
  form_name: string,
  pec_mode: string
): Promise<any> {
  const response = await request<Array<GeolocDestination>>({
    domain: Environment.findString("REACT_APP_API"),
    search: {
      action: "webservice_geolocation_request",
      origine: Environment.findString("REACT_APP_STEPS_ORIGIN"),
      ...responses,
      departement_code: dep,
      city,
      lat: lat.toString(),
      lng: lng.toString(),
      pec_mode,
      form_name,
    },
  });
  return response;
}

export type GeolocDestination = {
  btn_label: string;
  description: string;
  title: string;
  total_price: number;
  transit: string;
  value: string;
  warning: Array<string>;
};

async function finalizeQuotation(responses: QuotationResponses) {
  const payload = new FormData();
  payload.set("action", "generate_quote");
  payload.set("app_datas", JSON.stringify(responses));
  payload.set("origin", Environment.findString("REACT_APP_STEPS_ORIGIN"));
  return await request<FinalizeQuotationResponse>({
    body: payload,
    domain: Environment.findString("REACT_APP_API"),
    method: "POST",
  });
}

async function loadQuote(id: string) {
  const response = await request<QuotationFormValues>({
    domain: Environment.findString("REACT_APP_API"),
    search: {
      action: "edit_quote",
      quote_uuid: id,
    },
  });
  return response;
}

const Api = {
  initializeQuotation,
  continueQuotation,
  getFnaaInfos,
  finalizeQuotation,
  getGeolocDestinations,
  loadQuote,
};

export default Api;

//remappage des noms entre le webservice et l'interface
const REMAP: Record<string, string> = {
  prixVehic: "valeur",
  immatSiv: "immatriculation",
};

//les valeurs acceptables par l'application
const ESSENCE = "essence";
const DIESEL = "diesel";
const HYBRID = "hybride";
const ELECTRIC = "electrique";

const VOITURE = "voiture";
const UTILITAIRE = "utilitaire";
const MOTO = "moto";

const MAPPING: Record<string, string> = {
  ESSENCE: ESSENCE,
  "ESS+GAZO": ESSENCE,
  "ESS+G.P.L.": ESSENCE,
  "ESS+G.NAT": ESSENCE,
  BICARBUR: ESSENCE,
  SUPERETHANOL: ESSENCE,
  GAZOLE: DIESEL,
  "FUEL-OIL": DIESEL,
  "GAZOLE+GAZO": DIESEL,
  GAZOGENE: DIESEL,
  ELECTRIC: ELECTRIC,
  "PETROL.LAMP": DIESEL,
  INCONNUE: ESSENCE,
  AUTRES: ESSENCE,
};

//probleme, si genre=QM et carrosserie=QLOMP=>voiture
const TYPE_MAPPING: Record<string, string> = {
  VP: VOITURE,
  CTTE: UTILITAIRE, //TCP, CAM, CTTE,VASP, TRR, VTST, VTSU
  CAM: UTILITAIRE,
  TCP: UTILITAIRE,
  VASP: UTILITAIRE,
  TRR: UTILITAIRE,
  VTST: UTILITAIRE,
  VTSU: UTILITAIRE,
  MTL: MOTO,
  MTT1: MOTO,
  MTT2: MOTO,
  TMP1: MOTO,
  TMP2: MOTO,
  //tout le reste, moto
};
//si voiture, les carrosseries acceptées
const CARROSSERIES = [];

function parseDate(elem: Element) {
  let dt = [];
  //en theorie, 3 elements dans la date
  const children = Array.from(elem.children);
  for (let el of children) {
    let v = el.innerHTML;
    v = v.replace(/\D/g, "");
    dt.push(v);
  }
  return dt.join("/");
}

import * as Sentry from "@sentry/react";
import {
  Infer,
  create,
  number,
  object,
  optional,
  record,
  string,
} from "superstruct";

export const FnaaRawSchema = record(string(), string());
export type FnaaRaw = Infer<typeof FnaaRawSchema>;

export const FnaaParsedSchema = object({
  marque: string(),
  modele: string(),
  version: string(),
  longueur: optional(number()),
  largeur: optional(number()),
  hauteur: optional(number()),
  immatriculation: string(),
  cylindree: optional(number()),
  poids: optional(number()),
  motorisation: string(),
  ptac: optional(number()),
  type_vehicule: string(),
});

export type FnaaParsed = Infer<typeof FnaaParsedSchema>;

function parse(json: FnaaRaw) {
  const ptac = parseNumber(json["ptacPrf"], false);

  const merged = {
    marque: json["marque"],
    modele: json["modele"],
    version: json["version"],
    longueur: parseNumber(json["longueur"], false),
    largeur: parseNumber(json["largeur"], false),
    hauteur: parseNumber(json["hauteur"], false),
    poids: parseNumber(json["poidsVide"], false),
    ptac: ptac === undefined ? undefined : ptac * 10,
    cylindree: parseNumber(json["cylindree"], false),
    motorisation: json["energie"],
    immatriculation: json["immatriculation"],
    type_vehicule: json["type_vehicule"],
  };

  try {
    const output = create(merged, FnaaParsedSchema);
    return output;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
}

function parseNumber(input: any, allowZero: boolean = true) {
  if (typeof input === "number") return input;
  if (!input) return undefined;
  const nb = parseInt(input);
  if (isNaN(nb)) return undefined;
  if (!allowZero && nb === 0) return undefined;
  return nb;
}

const Fnaa = { parse };

export default Fnaa;

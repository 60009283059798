import { uniq, without } from "lodash";
import { nanoid } from "nanoid";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { Struct, assert } from "superstruct";
import Updater from "../../../utilities/Updater";

type FieldsFormProps = {
  manager?: FieldsFormManager;
  children: ReactNode;
  onReady?: (manager: FieldsFormManager) => any;
  onSubmit?: () => any;
};

export default function FieldsForm(props: FieldsFormProps) {
  const { children, onReady, onSubmit } = props;

  const manager = useMemo(() => {
    if (props.manager) return props.manager;
    else return new FieldsFormManager();
  }, [props.manager]);

  useEffect(() => {
    if (!onReady) return;
    onReady(manager);
  }, [onReady, manager]);

  const onSubmitForm = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!onSubmit) return;
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <FieldsFormContext.Provider value={manager}>
      <form id={manager.id} onSubmit={onSubmitForm} />
      {children}
    </FieldsFormContext.Provider>
  );
}

type FieldFormManagerOptions = {
  defaultValues?: Record<string, any>;
};
export class FieldsFormManager {
  readonly id = `form-${nanoid()}`;
  private submitting: boolean = false;
  private updater = new Updater();
  private readonly defaultValues: Record<string, any> = {};
  private readonly values: Record<string, any> = {};
  private readonly errors: Record<string, Array<string>> = {};

  constructor(options: FieldFormManagerOptions = {}) {
    this.defaultValues = options.defaultValues || {};
  }

  useFieldManager<TData>(key: string, validator: Struct<TData, any>) {
    const value = this.updater.useValue(() => {
      const v = this.values[key];
      if (v === undefined) {
        const defaultValue = this.defaultValues[key];
        if (defaultValue === undefined) return undefined;
        else if (defaultValue === null) return null;
        else {
          try {
            assert(defaultValue, validator);
            return defaultValue;
          } catch {
            return undefined;
          }
        }
      } else if (v === null) return null;
      else {
        try {
          assert(v, validator);
          return v;
        } catch (err) {
          console.log(err);
          return undefined;
        }
      }
    }, [key]);

    const setValue = useCallback(
      (v: TData | undefined | null) => {
        this.values[key] = v;
        this.updater.update();
      },
      [key]
    );

    const setError = useCallback(
      (error: string, enabled: boolean) => {
        let errors = this.errors[key] || [];
        if (enabled) {
          errors = uniq([...errors, error]);
        } else {
          errors = without(errors, error);
        }
        if (errors.length === 0) {
          delete this.errors[key];
        } else {
          this.errors[key] = errors;
        }
        this.updater.update();
      },
      [key]
    );

    return { value, setValue, setError };
  }

  setValue(key: string, value: any) {
    this.values[key] = value;
    this.updater.update();
  }

  cleanValue(key: string) {
    delete this.values[key];
    this.updater.update();
  }

  getValues() {
    return this.values;
  }

  setSubmitting(submitting: boolean) {
    this.submitting = submitting;
    this.updater.update();
  }

  clean(id: string) {
    delete this.values[id];
    delete this.errors[id];
    this.updater.update();
  }

  getState() {
    return {
      isSubmitting: this.submitting,
      hasErrors: Object.keys(this.errors).length > 0,
      errors: this.errors,
    };
  }

  useState() {
    return this.updater.useValue(() => {
      return this.getState();
    });
  }
}

export const FieldsFormContext = React.createContext<FieldsFormManager>(
  new FieldsFormManager()
);

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, ReactNode, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "reset-css";
import Button from "../components/Button";
import Content from "../components/Content";
import Grid from "../components/Grid";
import QuotationResumeTable from "../components/QuotationResumeTable";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import Api from "../utilities/Api";
import Layout from "../utilities/Layout";
import Quotations from "../utilities/Quotations";
import Theme from "../utilities/Theme";
import useAsyncCallback from "../utilities/useAsyncCallback";
import Loading from "./Loading";

export default function QuotationsHome() {
  const navigate = useNavigate();

  const quotations = Quotations.useQuotations();

  const [createQuotation, creating] = useAsyncCallback(async () => {
    const initialStep = await Api.initializeQuotation();
    const quotation = Quotations.create(initialStep);
    navigate(`/quotations/${quotation.id}/${initialStep.key}`);
  }, []);

  useEffect(() => {
    if (quotations.length === 0) createQuotation();
  }, [quotations]);

  const removeQuotation = useCallback((id: string) => {
    Quotations.removeQuotation(id);
  }, []);

  const theme = Theme.useTheme();

  const quotationContainerCss = css`
    border: 1px solid ${theme.bordersColor};
    border-radius: ${Layout.R}px;
    background-color: ${theme.panelBackground};
    overflow: hidden;
    padding: ${Layout.P}px;
  `;

  const buttonsCss = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  let quotationsNodes: ReactNode = null;
  if (quotations.length) {
    quotationsNodes = (
      <Fragment>
        <Button
          label="Commencez un nouveau devis"
          onClick={createQuotation}
          centered
        />
        <Spacer />
        <Content>
          <Text center>ou reprenez un devis commencé</Text>
        </Content>
        <Grid columns={2} gap={Layout.P} outerGap>
          {quotations.map((quotation, i) => {
            const lastStep = quotation.steps[quotation.steps.length - 1];
            const destination = `/quotations/${quotation.id}/${lastStep.key}`;
            return (
              <div css={quotationContainerCss} key={quotation.id}>
                <QuotationResumeTable resume={quotation.resume || []} />
                <Spacer />

                <div css={buttonsCss}>
                  <Button
                    label="Supprimer"
                    onClick={() => removeQuotation(quotation.id)}
                    minor
                  />
                  <Spacer />
                  <Button
                    centered
                    label="Continuer ce devis"
                    to={destination}
                  />
                </div>
              </div>
            );
          })}
        </Grid>
      </Fragment>
    );
  }

  return (
    <div>
      <Spacer.Double />
      <Text typo="heading" center>
        Votre devis gratuit instantané
      </Text>
      <Spacer.Double />
      <Content>
        <Text center>
          Calculez précisément le coût de transport de votre véhicule, conteneur
          ou colis en groupage entre la France métropolitaine et La Réunion ou
          Mayotte.
        </Text>
      </Content>
      <Spacer.Double />
      {creating ? (
        <Fragment>
          <Loading />
          <Spacer.Double />
        </Fragment>
      ) : (
        quotationsNodes
      )}
    </div>
  );
}

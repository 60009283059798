import { without } from "lodash";
import { nanoid } from "nanoid";
import React, { useContext } from "react";
import { AnyField } from "./Fields";
import StepContext from "./StepContext";
import Updater from "./Updater";

// Models

export type WorkflowItem = { key: string; label: string };

export type QuotationStep = {
  key: string;
  title: string;
  description: string;
  fields: Array<AnyField>;
  no_field_placeholder: string;
  workflow_mapped: Array<WorkflowItem>;
};

export type QuotationLastStep = {
  results: true;
};

export type QuotationFormValues = {
  [stepKey: string]: QuotationFormStepValues;
};

export type QuotationFormStepValues = {
  [fieldId: string]: any;
};

export type QuotationResume = Array<{
  stepKey: string;
  stepLabel: string;
  points: Array<{ info: string; value: string | Array<string> }>;
}>;

export type QuotationResponses = {
  [key: string]: any;
};

export type QuotationModel = {
  id: string;
  uuid: string | null;
  workflow: Array<WorkflowItem>;
  steps: Array<QuotationStep>;
  formValues: QuotationFormValues;
  resume: QuotationResume;
  result: QuotationResult | null | false;
};

// Initialisation

const localStorageKey = "quotations2";
const stored = localStorage.getItem(localStorageKey);
let quotations: Array<QuotationModel> = [];
const updater = new Updater();

if (stored) {
  const parsed = Object.values(JSON.parse(stored)) as Array<QuotationModel>;
  parsed.forEach((q) => {
    if (!isEmpty(q)) quotations.push(q);
  });
  write();
}

// Low level methods

function search(id: string) {
  return (
    quotations.find((q) => q.id === id) ||
    quotations.find((q) => q.uuid === id) ||
    null
  );
}

function exists(id: string) {
  return !!search(id);
}

function find(id: string) {
  const quotation = search(id);
  if (!quotation) throw new Error(`Quotation ${id} not found`);
  return quotation;
}

function upsert(quotation: QuotationModel) {
  if (exists(quotation.id)) update(quotation);
  else insert(quotation);
}

function update(quotation: QuotationModel) {
  quotations = quotations.map((q) =>
    q.id === quotation.id ? { ...quotation } : q
  );
  write();
}

function insert(quotation: QuotationModel) {
  if (exists(quotation.id)) return;
  quotations = [...quotations, { ...quotation }];
  write();
}

function remove(id: string) {
  quotations = quotations.filter((q) => q.id !== id);
  write();
}

// Storage methods

function write() {
  localStorage.setItem(localStorageKey, JSON.stringify(quotations));
  updater.update();
}

// Functional methods

function isEmpty(quotation: QuotationModel) {
  return Object.keys(quotation.formValues).length === 0;
}

function create(initialStep: QuotationStep) {
  const quotation: QuotationModel = {
    id: nanoid(8),
    uuid: null,
    workflow: initialStep.workflow_mapped,
    formValues: {},
    steps: [initialStep],
    result: null,
    resume: [],
  };
  insert(quotation);
  return quotation;
}

function inject(
  uuid: string,
  initialStep: QuotationStep,
  formValues: QuotationFormValues
) {
  const quotation: QuotationModel = {
    id: nanoid(8),
    uuid: uuid,
    workflow: initialStep.workflow_mapped,
    formValues: formValues,
    steps: [initialStep],
    result: null,
    resume: [],
  };
  insert(quotation);
  return quotation;
}

function searchQuotation(id: string) {
  return search(id);
}

function saveResult(id: string, result: QuotationResult | false | null) {
  const quotation = find(id);
  if (!quotation) throw new Error("No quotation found");
  quotation.result = result;
  update(quotation);
  return result;
}

function addResume(
  id: string,
  step: QuotationStep,
  field: AnyField,
  value: string | Array<string> | null
) {
  const quotation = find(id);

  const workflowItem = quotation.workflow.find((i) => i.key === step.key);
  if (!workflowItem) return;

  const info = "title" in field ? field.title : null;
  if (info === null) return;

  let category = quotation.resume.find((i) => i.stepKey === step.key);
  if (!category) {
    category = { stepKey: step.key, stepLabel: workflowItem.label, points: [] };
    quotation.resume.push(category);
  }

  let point = category.points.find((p) => p.info === info);
  if (!point) {
    if (!value) {
      if (category.points.length === 0)
        quotation.resume = without(quotation.resume, category);
    } else {
      point = { info, value };
      category.points.push(point);
    }
  } else {
    if (!value) {
      category.points = without(category.points, point);
      if (category.points.length === 0)
        quotation.resume = without(quotation.resume, category);
    } else {
      point.value = value;
    }
  }
  upsert(quotation);
  return quotation;
}

function saveFormValues(
  id: string,
  step: QuotationStep,
  stepFormValues: QuotationFormStepValues
) {
  const quotation = find(id);
  const stepKey = StepContext.getStepKey(step);
  quotation.formValues[stepKey] = { ...stepFormValues };
  update(quotation);
}

function addStep(id: string, currentStep: QuotationStep, step: QuotationStep) {
  const quotation = find(id);
  const workflow = step.workflow_mapped;
  const newSteps: Array<QuotationStep> = [];
  workflow.forEach((item) => {
    if (item.key === step.key) newSteps.push(step);
    else {
      const existing = quotation.steps.find((s) => s.key === item.key);
      if (existing) newSteps.push(existing);
    }
  }, []);
  quotation.workflow = step.workflow_mapped;
  quotation.steps = newSteps;
  update(quotation);
  return newSteps;
}

function useQuotation(id: string) {
  return updater.useValue(() => search(id), [id]);
}

function useQuotations() {
  return updater.useValue(() => quotations);
}

const context = React.createContext<QuotationModel | null>(null);

function useContextQuotation() {
  const quotation = useContext(context);
  if (!quotation) throw new Error("No quotation incontext");
  return quotation;
}

function removeQuotation(id: string) {
  remove(id);
  write();
}

function getResponses(id: string) {
  const quotation = find(id);
  const formValues = quotation.formValues;
  const steps = quotation.steps;
  const responses: QuotationResponses = {};
  steps.forEach((step) => {
    const stepKey = StepContext.getStepKey(step);
    const stepName = StepContext.getStepName(step);
    if (!formValues[stepKey]) return;
    if (step.fields.length === 1) {
      responses[stepName] = formValues[stepKey][step.fields[0].id];
    } else {
      responses[stepName] = formValues[stepKey];
    }
  });
  return responses;
}

function getFlatResponses(id: string, untilStep: string | null) {
  const quotation = find(id);
  if (!quotation) throw new Error("No quotation found");
  const steps = quotation.steps;
  let responses: QuotationResponses = {};
  for (let step of steps) {
    const stepKey = StepContext.getStepKey(step);
    responses = { ...responses, ...quotation.formValues[stepKey] };
    if (step.key === untilStep) break;
  }
  return responses;
}

const Quotations = {
  create,
  inject,
  addStep,
  useQuotation,
  saveResult,
  addResume,
  Provider: context.Provider,
  useContextQuotation,
  useQuotations,
  removeQuotation,
  saveFormValues,
  getResponses,
  getFlatResponses,
  searchQuotation,
};

export default Quotations;

export type QuotationResult = {
  calculated_datas: {
    A: number;
    ASSIGNE: number;
    CAF: number;
    EMITTER: string;
    FR: number;
    MODE_DE_TRANSPORT: string;
    OM: null | { om: number; omr: number };
    OM_VALUES: { OM: number; OMR: number };
    OPTIONS: [];
    P: number;
    PEC: string;
    PEC_LOCATION: string;
    PORT_ARRIVEE: string;
    PORT_DE_DEPART: string;
    PT: string;
    PT_LOCATION: string;
    QUOTE_ID: number;
    QUOTE_UUID: string;
    TAXES: number;
    TOTAL_A_PAYER: number;
    TOTAL_HT: number;
    TOTAL_REUNION: number;
    TSM: number;
    TSM_CONTENEUR_DETAILS: [];
    TVA: number;
    TVA_RU: number;
    TVA_RU_VALUE: number;
    TVA_VALUE: number;
    V: number;
  };
  delay: {
    formatted: string;
    raw: number;
  };
  pdf_file: string;
  url_reservation: string;
  summary: string;
  workflow: Array<WorkflowItem>;
};

import { useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Api from "../../utilities/Api";
import Quotations from "../../utilities/Quotations";
import Loading from "../Loading";

export default function QuotationReplay() {
  const uuid = useParams().uuid || "";
  const [redirect, setRedirect] = useState<string | null>();

  useMemo(async () => {
    if (!uuid) return;
    let existing = Quotations.searchQuotation(uuid);
    if (existing) Quotations.removeQuotation(existing.id);
    const initialStep = await Api.initializeQuotation();
    const formValues = await Api.loadQuote(uuid);
    if ("vehicule/form_marchandise" in formValues) {
      formValues["global/form_marchandise"] =
        formValues["vehicule/form_marchandise"];
      delete formValues["vehicule/form_marchandise"];
    }
    const quotation = Quotations.inject(uuid, initialStep, formValues);
    setRedirect(`/quotations/${quotation.id}/${initialStep.key}`);
  }, [uuid]);

  if (!uuid) {
    return <Navigate to="/" />;
  } else {
    if (!redirect) {
      return <Loading />;
    } else {
      return <Navigate to={redirect} replace />;
    }
  }
}

// http://192.168.1.28:3000/replay/dd46f173-fa79-4a4d-bf3e-9aef47de2b58

/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "reset-css";
import Api from "../utilities/Api";
import Quotations, {
  QuotationFormStepValues,
  QuotationModel,
  QuotationStep,
} from "../utilities/Quotations";
import StepContext from "../utilities/StepContext";
import Step from "./Step";

export default function QuotationContainer() {
  const path = useParams()["*"] || "";
  const [id, ...keyParts] = path.split("/");
  const key = keyParts.join("/");
  const quotation = Quotations.useQuotation(id);

  const step = useMemo(() => {
    if (!quotation) return null;
    return quotation.steps.find((s) => s.key === key);
  }, [quotation, key]);

  if (!quotation || !step) return <Navigate to="/" />;
  else return <Quotation quotation={quotation} step={step} />;
}

type QuotationProps = {
  quotation: QuotationModel;
  step: QuotationStep;
};

function Quotation(props: QuotationProps) {
  const { quotation, step } = props;

  useEffect(() => {
    Quotations.saveResult(quotation.id, null);
  }, []);

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (stepFormValues: QuotationFormStepValues) => {
      Quotations.saveFormValues(quotation.id, step, stepFormValues);
      const responses = Quotations.getFlatResponses(quotation.id, step.key);
      const nextStep = await Api.continueQuotation(step.key, responses);
      if ("key" in nextStep) {
        Quotations.addStep(quotation.id, step, nextStep);
        navigate(`/quotations/${quotation.id}/${nextStep.key}`);
      } else {
        navigate(`/quotations/${quotation.id}/resume`);
      }
    },
    [step]
  );

  if (!step) {
    return <Navigate to={"/"} />;
  } else {
    return (
      <Quotations.Provider value={quotation}>
        <Step
          key={StepContext.getStepKey(step)}
          step={step}
          defaultValues={quotation.formValues[StepContext.getStepKey(step)]}
          onSubmit={onSubmit}
        />
      </Quotations.Provider>
    );
  }
}

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback } from "react";
import Button from "../../../../components/Button";
import Selector from "../../../../components/Selector";
import Spacer from "../../../../components/Spacer";
import { GpsFieldLocation, GpsFieldOption } from "../../../../utilities/Fields";
import geolocalize from "../../../../utilities/geolocalize";

type LocationSelectorProps = {
  option: GpsFieldOption;
  selection: GpsFieldLocation | null;
  onSelect: (value: GpsFieldLocation | null) => any;
};

export default function LocaationSelector(props: LocationSelectorProps) {
  const { option, selection, onSelect } = props;

  const findClosest = useCallback(() => {
    geolocalize((coords) => {
      let closer: {
        option: GpsFieldOption;
        location: GpsFieldLocation;
        distance: number;
      } | null = null;

      for (let location of option.locations) {
        const distance = getDistance(
          coords.lat,
          coords.lng,
          location.lat,
          location.lng
        );
        if (closer === null || closer.distance > distance) {
          closer = { option, location, distance };
        }
      }

      if (closer !== null) {
        onSelect(closer.location);
      }
    });
  }, []);

  return (
    <div css={css({ display: "flex" })}>
      <div css={css({ flex: 1 })}>
        <Selector.Select
          options={option.locations}
          keyExtractor={(o) => o.value}
          renderOption={(o) => o.label}
          selection={selection}
          onSelect={onSelect}
          placeholder={option.placeholder}
        />
      </div>
      <Spacer />
      <Button label="Trouver l'option la plus proche" onClick={findClosest} />
    </div>
  );
}

function getDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1Rad = toRad(lat1);
  var lat2Rad = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1Rad) *
      Math.cos(lat2Rad);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRad(Value: number) {
  return (Value * Math.PI) / 180;
}
